<template>
  <div class="sub-menu">
    <div class="nav-bar">
      <div class="menu-links">
        <div class="menu-link" v-if="checkPermission('SFTH')">
          <router-link
            class="menu-item"
            to="/frais-th/simulation-facture-frais"
          >
            Simulation facture
          </router-link>
        </div>
      </div>
      <!-- <div class="menu-links">
        <div class="menu-link" v-if="checkPermission('Frais BDD')">
          <router-link class="menu-item" to="/frais-th/bdd">
            BDD
          </router-link>
        </div>
      </div> -->
      <!-- <div class="menu-links">
        <div
          class="menu-link"
          v-if="checkPermission('Récapitulatif gestion des frais')"
        >
          <router-link class="menu-item" to="/frais-th/vision-filiale">
            Gestion global des frais
          </router-link>
        </div>
      </div> -->
      <div class="menu-links">
        <div class="menu-link" v-if="checkPermission('GGDFTH')">
          <router-link class="menu-item" to="/frais-th/vision-globale-frais">
            Gestion global des frais
          </router-link>
        </div>
      </div>
      <!-- <div class="menu-links">
        <div
          class="menu-link"
          v-if="checkPermission('Gestion Commercial Cactus')"
        >
          <router-link class="menu-item" to="/frais-th/comercial-cactus">
            Gestion Commercial Cactus
          </router-link>
        </div>
      </div> -->
      <!-- <div class="menu-links">
        <div class="menu-link">
          <router-link class="menu-item" to="/frais-th/comercial-cactus">
            Gestion des variables responsable filiale
          </router-link>
        </div>
      </div> -->
      <!-- <div class="menu-links">
        <div class="menu-link" v-if="checkPermission('Gestion Bureau Etude')">
          <router-link class="menu-item" to="/frais-th/bureau-etude">
            Gestion Bureau d'etude
          </router-link>
        </div>
      </div> -->
      <!-- <div class="menu-links">
        <div class="menu-link" v-if="checkPermission('GFMTH')">
          <router-link class="menu-item" to="/frais-th/gestion-facture-mensuel">
            Gestion factures mensuelles
          </router-link>
        </div>
      </div> -->
      <!-- <div class="menu-links">
        <div class="menu-link" v-if="checkPermission('GRHTH')">
          <router-link class="menu-item" to="/frais-th/rh">
            Gestion Rh
          </router-link>
        </div>
      </div>-->
      <!-- <div class="menu-links">
        <div class="menu-link" v-if="checkPermission('Gestion Invest')">
          <router-link class="menu-item" to="/frais-th/energie-invest">
            Gestion Loyer EI - SCI
          </router-link>
        </div>
      </div> -->
      <!-- <div class="menu-links">
        <div
          class="menu-link"
          v-if="checkPermission('Gestion informatique web abondance')"
        >
          <router-link class="menu-item" to="/frais-th/web-abondance">
            Gestion Informatique WA
          </router-link>
        </div>
      </div> -->
      <!-- <div class="menu-links">
        <div class="menu-link" v-if="checkPermission('Gestion Interne IHS')">
          <router-link class="menu-item" to="/frais-th/interne-ihs">
            Interne IHS
          </router-link>
        </div>
      </div> -->
      <!-- <div class="menu-links">
        <div class="menu-link" v-if="checkPermission('GMFTH')">
          <router-link class="menu-item" to="/frais-th/master-filiale">
            Gestion master filliale
          </router-link>
        </div>
      </div> -->
      <!-- <div class="menu-links">
        <div class="menu-link" v-if="checkPermission('GMFTH')">
          <router-link class="menu-item" to="/frais-th/master-filiale-2">
            Gestion master filliale
          </router-link>
        </div>
      </div> -->
      <!-- <div class="menu-links">
        <div class="menu-link" v-if="checkPermission('GMFTH2')">
          <router-link class="menu-item" to="/frais-th/master-filiale-2">
            Gestion master filliale 2.0
          </router-link>
        </div>
      </div> -->
      <div class="notif-export-file" v-if="getShowNotifExportFile">
        Préparation du fichier en cours
      </div>
    </div>
    <div class="body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['checkPermission', 'getShowNotifExportFile'])
  }
};
</script>
